$grey-color: #6C7374;

.main-block {
  //background-color: #F6F8F8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  width: 100%;
  max-width: 550px;
  margin: auto;
  //height: 100vh;
  @media (min-width: 768px) {
    position: relative;
    background-color: #F6F8F8;
    width: 590px;
    box-shadow: 0 4px 34px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    overflow: hidden;
    min-height: 606px;
    height: max-content;
  }
}



.step {
  padding: 65px 24px 24px;
  @media (min-width: 375px) {
    padding: 80px 24px 24px;
  }
  @media (min-width: 768px) {
    padding: 80px 100px 24px;
    &--second {
      padding-top: 65px;
    }
  }
  &__title {
    font-size: 19px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 24px;
  }
  &__desc {
    text-align: center;
    margin-top: -18px;
    color: $grey-color;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 15px;
    @media (min-width: 375px) {
      line-height: 19px;
      font-size: 14px;
      margin-bottom: 24px;
    }
  }
  &__next {
    width: 100%;
    height: 56px;
    text-transform: uppercase;
    //background: #FFF;
    color: #009FAD;
    border: 2px solid #009FAD;
    box-shadow: 0 12px 16px rgba(90, 90, 90, 0.1);
    border-radius: 8px;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 36px;
    cursor: pointer;
    background-color: #F6F8F8;
    &:disabled {
      transition: .2s;
      //opacity: 0.3;
      border-color: #acdde1;
      color: #acdde1;
      cursor: inherit;
    }
  }
  &__next-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 20px;
    z-index: 2;
    button {
      margin-top: 0;
    }
    @media (min-width: 768px) {
      position: static;
      padding: 0;
      button {
        margin-top: 36px;
      }
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border: 5px solid #fff;
  border-top: 5px solid #009FAD;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




